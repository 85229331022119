@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #012840;
}

@layer base {
  html {
    font-family: Poppins, sans-serif;
  }
}
